<template>
    <div style="min-height:200px">
        <div class="mb-3">
            <!-- <div class="mt-1 mb-1 d-flex align-items-center">
            <router-link :to="'../'+id_client_data_main" class="d-flex align-items-center">
                <b-icon icon="arrow-left" class="mr-2"></b-icon>
                Naar profiel
            </router-link>
            </div> -->
        </div>
        <b-overlay :show="loading_list" :opacity="0.0">      
            <b-card class="mb-3"> 
                <app-view-table :isTitle="false" :loading="loading_list" :isTitleTotal="false" v-on:onRowClicked="item=>$set(item, '_showDetails', !item._showDetails)" :isMenuColumn="user_role < 3 || can_user_edit" :filters.sync="filters" :items="items" :fields="fields">
                
                    <template v-slot:menu-items="cell">
                        <b-dropdown-item @click="resendForm(cell.data.item)" v-if="cell.data.item.id_token_const_status >= 11 && cell.data.item.id_token_const_status <= 14 " >{{trans('resend-form',175)}}</b-dropdown-item>
                        <b-dropdown-item @click="blockForm(cell.data.item)" >{{trans('cancel',175)}}</b-dropdown-item>
                    </template>

                    <template v-slot:row-details="row">
                        <b-row class="app-card-header">
                            <b-col start>
                                <p class="col-list-label">{{trans('create-date',182)}}</p>
                                <p class="col-list-label">{{trans('expiration-date',3)}}</p>
                            </b-col>
                            <b-col>
                                <p class="col-form-value"> {{row.data.item.entry_date}} </p>
                                <p class="col-form-value"> {{row.data.item.expiry_date}} </p>
                            </b-col>                                    
                        </b-row>
                    </template>
                    
                </app-view-table>   
            </b-card>
            <template #overlay>
                <div></div>
            </template>
        </b-overlay>
    </div>
</template>

<script>

import axios from "@axios";
import AppAlerts from '@core/scripts/AppAlerts';

export default {

    props:["profile"],

    mounted() {
        this.onCreateThis();
    },


    data() {
        return {
            id_client_data_main: this.$route.params.id_client_data_main,
            filters: {
                sorting: {
                        field_name: null,
                        sort_direction: 0,
                        changed: 0
                },
                page: 1,
                per_page: 5,
                search: "",
                list_type: 1,
                changed: 0
            },
            items: [],
            fields: [
                { key: "type_name", label: this.trans('spieces',182), thStyle: { width: "100%" } , visible: true },
                { key: "date_log", label: this.trans('last-modification-info',182), thStyle: {  "min-width": "190px", "max-width": "190px" }, visible: true },
                { key: "status_name", label: this.trans('status',3), thStyle: {  "min-width": "160px", "max-width": "160px" }, visible: true },
            ],
            loading_list: false,
            alert_class: new AppAlerts(),
            user_role: JSON.parse(localStorage.getItem('user')).id_user_const_role,
            can_user_edit: false
        };
    },

    methods: {
        getList: function() {
            this.loading_list = true;
            axios
                .post("clients/profile/web-form/getList", {
                    id_client_data_main: this.id_client_data_main,
                    filters: JSON.stringify(this.filters),
                })
                .then((res) => {
                    this.filters.page = res.data.page;
                    this.items = res.data.items;
                    this.can_user_edit = res.data.can_user_edit;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading_list = false;
                });
        }, 

        blockForm(data){
            this.alert_class.openAlertConfirmation(this.trans('cancel-form-confirmation',180)).then(res => {
                if(res == true){
                    this.loading_list = true;
                    axios
                        .post("clients/profile/web-form/blockForm", {
                            id_client_data_main: this.id_client_data_main,
                            filters: JSON.stringify(this.filters),
                            id_token_data_main: data.id_token_data_main,
                        })
                         .then((res) => {
                            this.filters.page = res.data.page;
                            this.items = res.data.items;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        })
                        .finally(() => {
                            this.loading_list = false;
                        });; 
                }     
            }); 
                
        },
        
        resendForm(data){
            
            this.alert_class.openAlertConfirmation(this.trans('resend-form-confirmation',180)).then(res => {
                if(res == true){
                    this.loading_list = true;
                    axios
                        .post("clients/profile/web-form/resend", {
                            id_client_data_form: data.id,
                            id_client_data_main: this.id_client_data_main,
                            filters: JSON.stringify(this.filters),
                            
                        })
                         .then((res) => {
                            this.filters.page = res.data.page;
                            this.items = res.data.items;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        })
                        .finally(() => {
                            this.loading_list = false;
                        });; 
                }     
            }); 
        },

        onCreateThis() { 
            this.getList();
            this.$watch('filters.sorting.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('items.refresh', function(newVal, oldVal) {
                if (newVal > 0 && !this.loading_list) {
                    this.getList();
                }   
            });

            this.$watch('filters.changed',function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.page', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                }   
            });
        }
    },
};
</script>

<style scoped>
.vs__selected-options {
    font-size:9px;
}
</style>
